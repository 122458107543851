import { envs, orderStatuses } from "./constants";

export const getEnvDetails = () => {
  const env = process.env.NODE_ENV;

  return {
    isDev: env === envs.DEV,
    isProd: env === envs.PROD,
  };
};

export const convertToFittingDataType = (value) => {
  if (!value) return value;
  if (value === "true") return true;
  if (value === "false") return false;
  if (value.match(/^[+-]?[0-9]+$/)) return parseInt(value, 10);
  if (value.match(/^[+-]?\d+(\.\d+)?$/)) return parseFloat(value);
  return value;
};

export const convertEmailToName = (email) => {
  if (!email) return;
  // Split the email address into username and domain
  const [username] = email.split("@");

  const usernameParts = username.split(".");

  // Check if there are more than one parts in username
  if (usernameParts.length > 1) {
    // Capitalize the first letter of each part and join with a space
    const name = usernameParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    return name;
  } else {
    // If there's only one part, capitalize the first letter and return
    return username.charAt(0).toUpperCase() + username.slice(1);
  }
};

export const getNameInitials = (name = "") => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...(name || "").matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export const getRandomItemFromArray = (arr = []) =>
  arr[Math.floor(Math.random() * arr.length)];

export const getUniqueValuesFromArray = (arr = []) => [...new Set(arr)];

export const getSortOptions = (sortField) => {
  const sortParts = sortField.split("_");
  const sortOrder = sortParts[0];
  const sortBy = sortParts.slice(1).join("_");
  return [sortOrder, sortBy];
};

export const createRolesLabel = (roles) => {
  const firstTwo = roles
    .filter((_role, index) => index < 2)
    .map((role) => role?.roleName)
    .join(", ");
  const others = roles.length > 2 ? ` and ${roles.length - 2} more` : "";
  return `${firstTwo}${others}`;
};

export const normalizeData = (itemsArray = [], normalizingKey = "_id") => {
  return itemsArray.reduce(
    (normalizedData, item) => ({
      ...normalizedData,
      [item[normalizingKey]]: item,
    }),
    {}
  );
};

export const createDropdownOptionsWithSameValue = (value) => ({
  value,
  label: value,
});

export const truncate = (string = "", length = 20) =>
  string.length > length ? `${string.substring(0, length)}...` : string;

export const formatPhone = (phone = "") => {
  let formattedPhone = phone.replace("+234", "0");
  if (formattedPhone[0] !== 0 && formattedPhone.length === 10) {
    formattedPhone = `0${formattedPhone}`;
  }
  return `${formattedPhone.slice(0, 4)} ${formattedPhone.slice(
    4,
    7
  )} ${formattedPhone.slice(7, 11)}`;
};

export const isPhoneInvalid = (phone = "") => {
  const isPhoneLengthInvalid =
    phone.length !== (phone.startsWith("0") ? 11 : 10);
  return isPhoneLengthInvalid || !/^[0-9\b]+$/.test(phone);
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const getVehiclesDataMapping = (vehiclesData = []) => {
  const map = {};
  vehiclesData.forEach(({ model, registration }) => {
    map[model] = [...(map[model] || []), registration];
  });
  return map;
};

export const formatQuantity = (quantity) =>
  quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatAmount = (amount, toWholeNumber) => {
  if (Number.isNaN(amount) || isNaN(amount)) return "";
  if (toWholeNumber) {
    const value = Math.round(Number(amount));
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let formattedAmount = (+amount || 0).toFixed(2).toString();
  if (!formattedAmount.includes(".")) {
    formattedAmount = `${amount}.00`;
  }
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parseJWT = (token) => {
  if (!token) {
    return { permissions: [] };
  }
  const [, base64Url] = token.split(".");
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func?.apply(this, args);
    }, timeout);
  };
};

export const differenceInArrByKey = (arr1, arr2, key) =>
  arr2.filter((obj1) => !arr2.find((obj) => obj[key] === obj1[key]));

export const getOrderStatusColor = (status, statusLabel = "") => {
  let variant = "info";
  if (status === "cancelled") {
    variant = "error";
  } else if (status === "processing") {
    variant = "warn";
  } else if (status === "delivered" || status === "returned") {
    variant = "success";
  } else if (status === "undelivered") {
    variant = "warn2";
  } else if (status === "unvalidated") {
    variant = "pending";
  } else if (status === "archived") {
    variant = "inactive";
  } else if (status === "pending") {
    variant = "pending";
  } else if (status === "pending_cancellation") {
    variant = "warn";
  }

  if (statusLabel === "Archived") {
    variant = "inactive";
  }

  return variant;
};

export const getVehicleStatusColor = (status) => {
  let variant = "info";
  if (["assigning", "prepared", "dispatched"].includes(status)) {
    variant = "warn";
  } else if (status === "available") {
    variant = "success";
  } else if (status === "unavailable") {
    variant = "inactive";
  }

  return variant;
};

export const getOrderFutureStatus = (currentStatus, orderConfirmed = false) => {
  switch (currentStatus) {
    case "order_unvalidated":
      return [
        orderStatuses.order_created,
        orderStatuses.order_confirmed,
        orderStatuses.order_prepared,
        orderStatuses.order_verified,
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
    case "order_created":
      return [
        orderStatuses.order_confirmed,
        orderStatuses.order_prepared,
        orderStatuses.order_verified,
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
    case "order_modified":
      // eslint-disable-next-line no-case-declarations
      const statuses = [
        orderStatuses.order_prepared,
        orderStatuses.order_verified,
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
      if (!orderConfirmed) statuses.unshift(orderStatuses.order_confirmed);
      return statuses;
    case "order_role_assigned":
    case "order_confirmed":
      return [
        orderStatuses.order_prepared,
        orderStatuses.order_verified,
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
    case "order_prepared":
      return [
        orderStatuses.order_verified,
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
    case "order_verified":
      return [
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
    case "order_approved":
      return [orderStatuses.order_dispatched, orderStatuses.order_delivered];
    case "order_dispatched":
      return [orderStatuses.order_delivered];
    case "order_undelivered":
      return [
        orderStatuses.order_undelivered_approved,
        orderStatuses.order_undelivered_verified,
        orderStatuses.order_undelivered_accepted,
      ];
    case "order_undelivered_approved":
      return [
        orderStatuses.order_undelivered_verified,
        orderStatuses.order_undelivered_accepted,
      ];
    case "order_undelivered_verified":
      return [orderStatuses.order_undelivered_accepted];
    case "order_delivery_with_returns":
      return [
        orderStatuses.order_delivery_return_verified,
        orderStatuses.order_delivery_return_accepted,
      ];
    case "order_delivery_return_verified":
      return [orderStatuses.order_delivery_return_accepted];
    case "order_timeout":
      return [
        orderStatuses.order_confirmed,
        orderStatuses.order_prepared,
        orderStatuses.order_verified,
        orderStatuses.order_approved,
        orderStatuses.order_dispatched,
        orderStatuses.order_delivered,
      ];
    case "order_pending_cancellation":
      return [orderStatuses.order_cancelled];
    default:
      return [];
  }
};

export const formatDate = (date, st = true) => {
  const d = new Date(date);
  let dd;
  if (st) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();
    dd = [day, month, year].join(" ");
  } else {
    const month =
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const year = d.getFullYear();
    dd = [year, month, day].join("-");
  }
  return dd;
};

export const addOneDayToDate = (date, format = true) => {
  const newDate = new Date(date);
  const currentDate = new Date();
  newDate.setDate(newDate.getDate() + 1);
  currentDate.setDate(currentDate.getDate() + 1);
  if (newDate > currentDate) {
    return format ? formatDate(currentDate) : currentDate;
  }
  return format ? formatDate(newDate) : newDate;
};

export const getStartAndEndDate = (daysBack, options) => {
  const { addOneDayToEndDate = false, format = false } = options || {};
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - Number(daysBack));
  endDate.setDate(endDate.getDate() + (addOneDayToEndDate ? 1 : 0));
  if (!format) {
    return {
      startDate: new Date(startDate).toISOString().split("T")[0],
      endDate: new Date(endDate).toISOString().split("T")[0],
    };
  }
  return {
    startDate: formatDate(new Date(startDate).toISOString().split("T")[0]),
    endDate: formatDate(new Date(endDate).toISOString().split("T")[0]),
  };
};

export const downloadURL = (uri, name) => {
  const link = document.createElement("a");
  link.setAttribute("download", name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeDuplicatesInObjArr = (arr, uniqueIdentifier) => {
  const seen = new Set();
  return arr.filter((obj) => {
    const duplicate = seen.has(obj[uniqueIdentifier]);
    seen.add(obj[uniqueIdentifier]);
    return !duplicate;
  });
};

export const sortArrOfObj = (arr, sortKey) => {
  function compare(a, b) {
    if (a[sortKey].toLowerCase() < b[sortKey].toLowerCase()) {
      return -1;
    }
    if (a[sortKey].toLowerCase() > b[sortKey].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  return [...arr]?.sort(compare);
};

export const capitalizeFirstLetter = (value) => {
  value = value?.toLowerCase();
  return value?.charAt(0)?.toUpperCase() + value?.slice(1);
};

export const convertNairaToKobo = (amount) =>
  Number.isNaN(amount) ? 0 : amount * 100;
export const convertKoboToNaira = (amount) =>
  Number.isNaN(amount) ? 0 : amount / 100;

export const removeUnderscore = (str) => {
  if (str?.includes("_")) {
    const words = str.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  } else if (str?.includes(" ")) {
    const words = str.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  } else {
    return str?.length > 1
      ? str?.charAt(0).toUpperCase() + str?.slice(1)
      : str?.charAt(0).toUpperCase();
  }
};
